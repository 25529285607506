<script setup lang="ts">
import { defineLayout, useHead } from '#imports'

defineLayout({
  label: 'Redesigned Layout',
})

useHead({
  htmlAttrs: {
    lang: 'en',
  },
})
</script>

<template>
  <div class="redesigned-layout">
    <div class="bg-slate-200 dark:bg-slate-950 overflow-auto font-sans">
      <slot />
    </div>
  </div>
</template>
